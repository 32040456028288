import type React from "react";
import { useEffect, useCallback } from "react";
import { Button } from "@/components/ui/button";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { TronLinkAdapterName } from "@tronweb3/tronwallet-adapter-tronlink";
import { toast } from "sonner";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectValue,
	SelectTrigger,
} from "@/components/ui/select";
import { ConnectButton } from "@/components/connectButton";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { env } from "@/env";
import { useTranslation } from "react-i18next";
import { useMarketData } from "@/hooks/useMarketData";
import Marquee from "react-fast-marquee";

// Constants
const TRONLINK_IMAGE = "/tronlink.jpg";
const ADDRESS_CHARS = 8;
const LANGUAGES = [
	{ value: "en", label: "English" },
	{ value: "cn", label: "Chinese" },
] as const;

const shortenAddress = (address: string, chars = ADDRESS_CHARS): string => {
	if (!address) return "";
	return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

interface WalletButtonProps {
	isMobile?: boolean;
	onDisconnect: () => void;
	address: string;
}

interface MobileMenuProps {
	connected: boolean;
	onDisconnect: () => void;
	address: string;
}

const WalletButton: React.FC<WalletButtonProps> = ({
	isMobile = false,
	onDisconnect,
	address,
}) => (
	<Button
		variant="secondary"
		onClick={onDisconnect}
		className={`flex items-center justify-start gap-2 ${isMobile ? "w-full" : ""}`}
	>
		<img src={TRONLINK_IMAGE} alt="TronLink" className="h-5 rounded-sm" />
		<span className="truncate">{shortenAddress(address)}</span>
	</Button>
);

const MobileMenu: React.FC<MobileMenuProps> = ({
	connected,
	onDisconnect,
	address,
}) => {
	const { i18n, t } = useTranslation();

	return (
		<div className="flex flex-col gap-4">
			{connected ? (
				<WalletButton isMobile onDisconnect={onDisconnect} address={address} />
			) : (
				<ConnectButton />
			)}
			<Select value={i18n.language} onValueChange={i18n.changeLanguage}>
				<SelectTrigger className="w-full">
					<SelectValue />
				</SelectTrigger>
				<SelectContent>
					{LANGUAGES.map(({ value, label }) => (
						<SelectItem key={value} value={value}>
							{t(label)}
						</SelectItem>
					))}
				</SelectContent>
			</Select>
		</div>
	);
};

// Main component
export const Header: React.FC = () => {
	const { select, connected, disconnect, wallet } = useWallet();
	const { data, isLoading } = useMarketData();
	const { i18n, t } = useTranslation();

	// biome-ignore lint/correctness/useExhaustiveDependencies: Needs to be re-run when wallet changes
	useEffect(() => {
		select(TronLinkAdapterName);
		console.log(data);
	}, [select, wallet]);

	const handleDisconnect = useCallback(async () => {
		try {
			await disconnect();
		} catch (error) {
			toast.error(t("errrorDisconnectingWallet"));
			console.error(t(":"), error);
		}
	}, [disconnect, t]);

	const address = wallet?.adapter.address || "";

	return (
		<>
			{!isLoading && data?.helloBar && (
				<div className="w-full py-1 font-semibold text-[18px] text-primary pt-3">
					<div className="max-w-[1250px] mx-auto overflow-hidden">
						<Marquee gradient={false} speed={50}>
							{data.helloBar}
						</Marquee>
					</div>
				</div>
			)}
			<header className="fixed top-0 z-50 flex h-[70px] w-full items-center justify-center border-b bg-card/80 py-2 text-card-foreground text-md shadow-sm backdrop-blur-md">
				<div className="mx-auto flex w-full max-w-[1250px] items-center justify-between px-2 md:grid md:grid-cols-layout md:gap-4">
					<Link
						to="/"
						className="flex items-center justify-center gap-2 overflow-hidden font-black"
					>
						<img src="/logo-row.webp" alt="logo" className="h-9 md:h-11" />
					</Link>
					<div className="hidden items-center justify-end gap-2 md:flex md:flex-row">
						<div className="flex flex-row items-center gap-2">
							{/* Add flex properties here */}
							{connected ? (
								<WalletButton onDisconnect={handleDisconnect} address={address} />
							) : (
								<ConnectButton />
							)}
							<Select value={i18n.language} onValueChange={i18n.changeLanguage}>
								<SelectTrigger>
									<SelectValue />
								</SelectTrigger>
								<SelectContent>
									{LANGUAGES.map(({ value, label }) => (
										<SelectItem key={value} value={value}>
											{t(label)}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
						{/* <ThemeToggle /> */}
					</div>
					<div className="md:hidden">
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon">
									<Menu className="h-[1.2rem] w-[1.2rem]" />
								</Button>
							</SheetTrigger>
							<SheetContent>
								<MobileMenu
									connected={connected}
									onDisconnect={handleDisconnect}
									address={address}
								/>
							</SheetContent>
						</Sheet>
					</div>
				</div>
			</header>
		</>
	);
};
